import * as React from "react";

function SvgSearchActive(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="up-arrow 1" clip-path="url(#clip0)">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M4 245.333C4 112.268 112.268 4 245.333 4C378.399 4 486.666 112.268 486.666 245.333C486.666 378.398 378.398 486.666 245.333 486.666C112.268 486.666 4 378.398 4 245.333ZM17.333 245.333C17.333 371.062 119.604 473.333 245.333 473.333C371.062 473.333 473.333 371.062 473.333 245.333C473.333 119.604 371.062 17.333 245.333 17.333C119.604 17.333 17.333 119.604 17.333 245.333Z"
              fill="black"
              stroke="white"
              stroke-width="8"
            />
          </g>
        </g>
        <g id="Group_3">
          <g id="Group_4">
            <path
              id="Vector_2"
              d="M238.666 117.334C238.666 113.655 241.654 110.667 245.333 110.667C249.012 110.667 252 113.655 252 117.333V373.333C252 377.012 249.011 380.001 245.333 380.001C241.654 380.001 238.666 377.013 238.666 373.334V117.334Z"
              fill="black"
              stroke="white"
              stroke-width="8"
            />
          </g>
        </g>
        <g id="Group_5">
          <g id="Group_6">
            <path
              id="Vector_3"
              d="M248.163 129.589L245.334 126.76L242.506 129.589L164.725 207.37C162.127 209.967 157.896 209.967 155.298 207.37C152.701 204.772 152.701 200.541 155.298 197.943L240.631 112.61C243.229 110.013 247.46 110.013 250.058 112.61L335.391 197.943C337.988 200.541 337.989 204.77 335.391 207.371C334.076 208.685 332.375 209.335 330.667 209.335C328.96 209.335 327.254 208.685 325.935 207.383C325.933 207.381 325.931 207.379 325.928 207.377L248.163 129.589Z"
              fill="black"
              stroke="white"
              stroke-width="8"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="490.667" height="490.667" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgSearchActive;
