import * as React from "react";

function SvgMix(props) {
  return (
    <svg height="1em" 
    width="1em" 
    viewBox="0 0 32 32" 
    {...props} xmlns="http://www.w3.org/2000/svg">
<path d="M5 24.7502C4.99983 25.3471 4.73628 25.9194 4.26733 26.3413C3.79837 26.7632 3.16242 27.0001 2.49937 27C1.83633 26.9999 1.20051 26.7626 0.731791 26.3405C0.263067 25.9184 -0.000165645 25.3459 7.42746e-08 24.7491C0.000165803 24.1524 0.263651 23.5803 0.732491 23.1584C1.20133 22.7366 1.83712 22.4998 2.5 22.4999C3.16287 22.5001 3.79854 22.7372 4.26714 23.1592C4.73575 23.5813 4.99892 24.1536 4.99875 24.7502H5ZM5 13.5003C4.99983 14.0971 4.73628 14.6694 4.26733 15.0913C3.79837 15.5133 3.16242 15.7502 2.49937 15.75C1.83633 15.7499 1.20051 15.5127 0.731791 15.0905C0.263067 14.6684 -0.000165645 14.096 7.42746e-08 13.4992C7.42746e-08 12.9025 0.263327 12.3302 0.732051 11.9083C1.20077 11.4864 1.8365 11.2494 2.49937 11.2494C3.16225 11.2494 3.79798 11.4864 4.2667 11.9083C4.73542 12.3302 4.99875 12.9025 4.99875 13.4992L5 13.5003ZM5 2.25033C4.99983 2.84715 4.73628 3.41947 4.26733 3.84139C3.79837 4.2633 3.16242 4.50024 2.49937 4.50009C1.83633 4.49994 1.20051 4.26271 0.731791 3.84059C0.263067 3.41847 -0.000165645 2.84603 7.42746e-08 2.2492C0.000165803 1.65253 0.263651 1.08035 0.732491 0.658542C1.20133 0.236735 1.83712 -0.000149591 2.5 -4.22236e-07C3.16287 0.000148746 3.79854 0.23732 4.26714 0.659338C4.73575 1.08136 4.99892 1.65365 4.99875 2.25033H5Z" fill="white"/>
</svg>
  );
}



export default SvgMix;
