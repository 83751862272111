import * as React from "react";

function SvgProfile(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="up-arrow 1" clip-path="url(#clip0)">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M245.333 0C110.059 0 0 110.059 0 245.333C0 380.607 110.059 490.666 245.333 490.666C380.607 490.666 490.666 380.607 490.666 245.333C490.666 110.059 380.608 0 245.333 0ZM245.333 469.333C121.813 469.333 21.333 368.853 21.333 245.333C21.333 121.813 121.813 21.333 245.333 21.333C368.853 21.333 469.333 121.813 469.333 245.333C469.333 368.853 368.853 469.333 245.333 469.333Z"
              fill="white"
            />
          </g>
        </g>
        <g id="Group_3">
          <g id="Group_4">
            <path
              id="Vector_2"
              d="M245.333 106.667C239.445 106.667 234.666 111.446 234.666 117.334V373.334C234.666 379.222 239.445 384.001 245.333 384.001C251.221 384.001 256 379.221 256 373.333V117.333C256 111.445 251.221 106.667 245.333 106.667Z"
              fill="white"
            />
          </g>
        </g>
        <g id="Group_5">
          <g id="Group_6">
            <path
              id="Vector_3"
              d="M338.219 195.115L252.886 109.782C248.726 105.622 241.963 105.622 237.803 109.782L152.47 195.115C148.31 199.275 148.31 206.038 152.47 210.198C156.63 214.358 163.393 214.358 167.553 210.198L245.334 132.417L323.115 210.22C325.206 212.289 327.936 213.335 330.667 213.335C333.398 213.335 336.128 212.29 338.219 210.199C342.379 206.037 342.379 199.275 338.219 195.115Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="490.667" height="490.667" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgProfile;
